import { render, staticRenderFns } from "./AddressPopup.vue?vue&type=template&id=c7bd0712&scoped=true&"
import script from "./AddressPopup.vue?vue&type=script&lang=js&"
export * from "./AddressPopup.vue?vue&type=script&lang=js&"
import style0 from "./AddressPopup.vue?vue&type=style&index=0&id=c7bd0712&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7bd0712",
  null
  
)

export default component.exports